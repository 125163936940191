import cn from 'classnames'
import { useRouter } from 'next/router'
import { useMemo, useState, useEffect, useCallback } from 'react'
import { AnimatePresence, motion, useScroll } from 'framer-motion'
import throttle from 'lodash.throttle'
import Icon from '@components/icons'
import { useCart } from 'lib/shopify/api/cart'
import { useUI } from '@components/ui/context'
import { commerceEnabled } from '@lib/utils/tools'
import { easingTransitions } from '@lib/utils/animate'

import UnfoldWingsPC from './UnfoldWingsPC'
import UnfoldWingsMobile from './UnfoldWingsMobile'
import LiveComponent from './LiveComponent'

import s from './Header.module.css'
import { Picture } from '@components/ui'
import MobileSearchBoard from '@components/common/Searchbar/SearchBoard'
import Notification from '@components/common/Notification'
import { onSearchClick } from './trace'
import { $ } from '@components/helper'
import Cookies from 'js-cookie'
import { useRelativeLink } from '@commerce/product/use-relative-link'
import Ankerlogo from './Ankerlogo'

import { UserNavChg } from '@components/common/UserNav'
import { User } from '@components/common/UserNav/UserNavChg'

const Header = ({
  page,
  mode = {},
  lang,
  shopCommon,
  categories,
  shop_collections,
  dropDownContent,
  dropDownSmallContent,
  dropDownRightContent,
  liveContent,
  pageType,
  navSetting,
  metafields,
}) => {
  const router = useRouter()
  const { locale } = router
  const { data } = useCart()
  const [showSearchBoard, setShowSearchBoard] = useState(false)
  const [showMobileSearchBoard, setShowMobileSearchBoard] = useState(false)
  const { setRelativeLink } = useRelativeLink()
  const {
    navUser,
    preRender,
    shopPreRender,
    navNotification,
    registrationsSettings,
  } = metafields || {}
  const {
    toggleSidebar,
    setSidebarView,
    menuTheme,
    setRegistrationsPopup,
    setRegistrationsModelStatus,
  } = useUI()

  const [collectionHover, setCollectionHover] = useState(0)

  const [scrollHiddenTransparent, setScrollHiddenTransparent] = useState(false)
  const [downShow, setShow] = useState(true)
  const [fixedReady, setReady] = useState(false)

  const [dropDownBoxOpen, setDropDownBoxOpen] = useState(false)

  const [dropDownBoxContent, setDropDownBoxContent] = useState('')

  const [dropDownSmallBoxContent, setDropDownSmallBoxContent] = useState('')

  const [dropDownRightBoxContent, setDropDownRightBoxContent] = useState('')

  const [dropDownPositionDefault, setDropDownPosition] = useState(0)

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const [userPopLeft, setUserPopLeft] = useState(false)

  const [notificationPopLeft, setNotificationPopLeft] = useState(false)

  const [isNotification, setIsNotification] = useState(false)

  const [showNavMainBoxBg, setNavMainBoxBg] = useState(false)

  const [showUserBoard, setShowUserBoard] = useState(false)

  const [rootDark, rootTransparent] = useMemo(() => {
    if (scrollHiddenTransparent) return [false, false]
    if (dropDownBoxOpen || showSearchBoard || showNavMainBoxBg)
      return [false, false]
    if (menuTheme === 'dark' && navSetting?.navBg === 'transparent')
      return [true, true]
    if (navSetting?.navBg === 'transparent') return [false, true]
    return [false, false]
  }, [
    scrollHiddenTransparent,
    dropDownBoxOpen,
    showSearchBoard,
    menuTheme,
    navSetting?.navBg,
    showNavMainBoxBg,
  ])
  const CUSTOMERAUTH = useMemo(
    () => commerceEnabled('customerAuth', locale),
    [locale]
  )
  const SEARCH = useMemo(() => commerceEnabled('search', locale), [locale])
  const CART = useMemo(() => commerceEnabled('cart', locale), [locale])

  const navMode = useMemo(() => {
    if (navSetting?.navMode === 'none-fixed') return 'none-fixed'
    if (navSetting?.navMode === 'fixed') return 'fixed'
    return 'normal'
  }, [navSetting?.navMode])

  useEffect(() => {
    const width = document?.querySelector('body')?.offsetWidth || 0
    // transparent nav滚动展示逻辑专用函数
    if (navSetting?.navBg === 'transparent') {
      const removeTransparent = throttle((scrollTop) => {
        if (scrollTop > (width > 1024 ? 200 : 50) && !scrollHiddenTransparent) {
          setScrollHiddenTransparent(true)
        } else if (
          scrollTop < (width > 1024 ? 100 : 50) &&
          scrollHiddenTransparent
        ) {
          setScrollHiddenTransparent(false)
        }
      })

      const scrollAnimate = () => {
        let scrollTop = 0
        if (document?.documentElement && document?.documentElement?.scrollTop) {
          scrollTop = document?.documentElement.scrollTop
        } else if (document?.body) {
          scrollTop = document?.body.scrollTop
        }
        removeTransparent(scrollTop)
      }

      window.addEventListener('scroll', scrollAnimate)

      return () => window.removeEventListener('scroll', scrollAnimate)
    }
  })

  useEffect(() => {
    // nav滚动展示逻辑专用函数
    if (navMode === 'normal') {
      let lastValue = 0

      const setFixed = throttle((scrollTop) => {
        if (scrollTop > 1 && !fixedReady) {
          setReady(true)
        } else if (scrollTop <= 1 && fixedReady) {
          setReady(false)
        }
      })

      let totalScrolledUp = 0
      const showDownNav = throttle((scrollTop) => {
        if (scrollTop <= lastValue && fixedReady) {
          totalScrolledUp += lastValue - scrollTop
          if (totalScrolledUp > 300) {
            setShow(true)
            totalScrolledUp = 0
          }
          if (scrollTop <= 50) {
            setNavMainBoxBg(false)
          }
        } else if (scrollTop > 400 && fixedReady) {
          setShow(false)
          totalScrolledUp = 0
        } else if (scrollTop > 50 && fixedReady) {
          setNavMainBoxBg(true)
        } else {
          setShow(true)
        }
        lastValue = scrollTop
      }, 200)

      const scrollAnimate = () => {
        let scrollTop = 0
        if (document?.documentElement && document?.documentElement?.scrollTop) {
          scrollTop = document?.documentElement.scrollTop
        } else if (document?.body) {
          scrollTop = document?.body.scrollTop
        }
        setFixed(scrollTop)
        showDownNav(scrollTop)
      }

      window.addEventListener('scroll', scrollAnimate)

      return () => window.removeEventListener('scroll', scrollAnimate)
    }
  }, [fixedReady, navMode])

  useEffect(() => {
    let isNotification = Cookies.get('isNotification')
    if (!isNotification) {
      setIsNotification(true)
    }
  }, [])

  const countItem = (count, item) => count + item.quantity
  const itemsCount = data?.lineItems?.reduce(countItem, 0) ?? 0

  const getCollection = (handle) => {
    return categories.find((item) => item?.slug === handle)
  }

  const getMainCollection = (handle) => {
    return shop_collections?.[handle]
  }

  const hasLiveContent = useMemo(() => {
    if (liveContent) {
      let nowDate = new Date().getTime()
      let start = new Date(liveContent?.start).getTime()
      let end = new Date(liveContent.end).getTime()
      if (start <= nowDate && end >= nowDate) {
        return { ...liveContent, hasLive: true }
      }
    }
    return liveContent
  }, [liveContent])

  const getProductList = ({ collectionProducts, productList }) => {
    if (!productList || productList?.length == 0) return []
    if (!collectionProducts || collectionProducts?.length == 0) return []
    const collection_prodcts = collectionProducts
      ?.filter((product) => {
        const current = productList.find(
          (item) => item?.handle === product.handle
        )
        return current
      })
      ?.map((product) => {
        return {
          ...product,
          variants:
            product.variants?.length <= 1
              ? product.variants
              : product.variants.filter((variant) => {
                  const current = productList.find(
                    (item) => item?.sku === variant.sku
                  )
                  return current
                }),
        }
      })

    return collection_prodcts
  }

  const handleNewCollections = ({ current }) => {
    const { content: cloneContent } = current
    const content =
      cloneContent?.length > 0
        ? cloneContent?.map((item) => {
            const collection = getCollection(item?.handle)
            if (item?.has_custom) {
              return {
                ...(item || {}),
                name: collection?.name,
                path: collection?.path,
                productList: getProductList({
                  collectionProducts: collection?.products || [],
                  productList: item?.productList,
                }),
                collectionList:
                  item?.childCollectionList?.map((j) => {
                    const cur = getCollection(j?.handle)
                    return {
                      ...(j || {}),
                      name: cur?.name,
                      path: cur?.path,
                      productList: getProductList({
                        collectionProducts: cur?.products || [],
                        productList: j?.productList || [],
                      }),
                    }
                  }) || [],
              }
            } else {
              return {
                ...(item || {}),
                name: collection?.name,
                path: collection?.path,
                productList: collection?.products || [],
                collectionList:
                  item?.collectionList?.map((j) => {
                    const cur = getCollection(j?.handle)
                    return {
                      ...(j || {}),
                      name: cur?.name,
                      path: cur?.path,
                      productList: cur?.products || [],
                    }
                  }) || [],
              }
            }
          })
        : []

    return content
  }

  const currentDownBoxContent = useMemo(() => {
    const current =
      dropDownContent &&
      dropDownContent?.find((item) => item?.text === dropDownBoxContent)

    if (current?.['content-banner']?.length) {
      current.content = current?.['content-banner']
    } else if (current?.['content-gather']?.length) {
      current.content = current?.['content-gather']
    } else if (current?.['scene-banner']?.length) {
      current.content = current?.['scene-banner']
    } else if (current?.content?.length) {
      current.content =
        current?.mode === 'newCollections'
          ? handleNewCollections({ current })
          : current?.content?.map((item) => {
              if (item?.handle) {
                const minCollection = getMainCollection(item?.handle)
                const collection = getCollection(item?.handle)
                let collectionList =
                  minCollection?.map((handle) => {
                    const current = getCollection(handle)
                    return { name: current?.name, path: current?.path }
                  }) || []

                if (item?.otherLinks) {
                  collectionList = collectionList?.concat(
                    item?.otherLinks?.map((link) => ({
                      name: link?.label,
                      path: link?.to,
                    }))
                  )
                }
                return {
                  ...(item || {}),
                  handle: item?.handle,
                  name: collection?.name,
                  path: collection?.path,
                  productList: collection?.products,
                  collectionList,
                }
              }
              return item
            })
    }
    return current
  }, [dropDownBoxContent, dropDownContent, categories])

  const currentDownBoxContentAll = useMemo(() => {
    return dropDownContent?.map((current) => {
      if (current?.['content-banner']?.length) {
        current.content = current?.['content-banner']
      } else if (current?.['scene-banner']?.length) {
        current.content = current?.['scene-banner']
      } else if (current?.['content-gather']?.length) {
        current.content = current?.['content-gather']
      } else if (current?.content?.length) {
        current.content =
          current?.mode === 'newCollections'
            ? handleNewCollections({ current })
            : current?.content?.map((item) => {
                if (item?.handle) {
                  const minCollection = getMainCollection(item?.handle)
                  const collection = getCollection(item?.handle)
                  let collectionList =
                    minCollection?.map((handle) => {
                      const current = getCollection(handle)
                      return { name: current?.name, path: current?.path }
                    }) || []

                  if (item?.otherLinks) {
                    collectionList = collectionList?.concat(
                      item?.otherLinks?.map((link) => ({
                        name: link?.label,
                        path: link?.to,
                      }))
                    )
                  }
                  return {
                    ...(item || {}),
                    handle: item?.handle,
                    name: item?.text || collection?.name,
                    path: collection?.path,
                    productList: collection?.products,
                    collectionList,
                  }
                }
                return item
              })
      }
      return current
    })
  }, [dropDownBoxContent, dropDownContent, categories])

  const currentSmallBoxContent = useMemo(() => {
    return (
      dropDownSmallBoxContent &&
      dropDownSmallContent?.find(
        (item) => item?.text === dropDownSmallBoxContent
      )
    )
  }, [dropDownSmallBoxContent, dropDownSmallContent])

  const currentRightBoxContent = useMemo(() => {
    const current =
      dropDownRightContent &&
      dropDownRightContent?.find(
        (item) => item?.text === dropDownRightBoxContent
      )

    if (current?.['content-banner']?.length) {
      current.content = current?.['content-banner']
    } else if (current?.['content-gather']?.length) {
      current.content = current?.['content-gather']
    } else if (current?.['scene-banner']?.length) {
      current.content = current?.['scene-banner']
    } else if (current?.content?.length) {
      current.content =
        current?.mode === 'newCollections'
          ? handleNewCollections({ current })
          : current?.content?.map((item) => {
              if (item?.handle) {
                const minCollection = getMainCollection(item?.handle)
                const collection = getCollection(item?.handle)
                let collectionList =
                  minCollection?.map((handle) => {
                    const current = getCollection(handle)
                    return { name: current?.name, path: current?.path }
                  }) || []

                if (item?.otherLinks) {
                  collectionList = collectionList?.concat(
                    item?.otherLinks?.map((link) => ({
                      name: link?.label,
                      path: link?.to,
                    }))
                  )
                }
                return {
                  ...(item || {}),
                  handle: item?.handle,
                  name: collection?.name,
                  path: collection?.path,
                  productList: collection?.products,
                  collectionList,
                }
              }
              return item
            })
    }
    return current
  }, [dropDownRightBoxContent, dropDownRightContent, categories])

  const dropDownPosition = useMemo(() => {
    if (!dropDownPositionDefault) return 0
    if (dropDownPositionDefault <= 0) return 0
    return currentSmallBoxContent?.imgTitle
      ? dropDownPositionDefault - 250
      : dropDownPositionDefault - 180
  }, [dropDownPositionDefault, currentSmallBoxContent])

  const mobileShowDetail = useMemo(
    () =>
      dropDownBoxContent || dropDownSmallBoxContent || dropDownRightBoxContent,
    [dropDownBoxContent, dropDownSmallBoxContent, dropDownRightBoxContent]
  )

  const onMouseLeaveHidden = () => {
    setDropDownBoxContent('')
    setDropDownSmallBoxContent('')
    setDropDownRightBoxContent('')
    setDropDownBoxOpen(false)
    setUserPopLeft(false)
    setNotificationPopLeft(false)
    setNavMainBoxBg(false)
  }

  const onMobileMenuHidden = () => {
    setMobileMenuOpen(false)
    setDropDownSmallBoxContent('')
    setDropDownRightBoxContent('')
    setShowMobileSearchBoard(false)
    setCollectionHover(-1)
    setDropDownBoxContent('')
    setDropDownBoxOpen(false)
    setNavMainBoxBg(false)
  }

  const handleRegistrations = useCallback(
    (e, type) => {
      onMobileMenuHidden()
      if (!registrationsSettings) return
      e.preventDefault()
      setRegistrationsPopup(true)
      setRegistrationsModelStatus(type === 'signIn' ? 'quickLogin' : 'main')
    },
    [setRegistrationsPopup, setRegistrationsModelStatus, registrationsSettings]
  )

  const isShowNext = useMemo(() => {
    if (
      (currentDownBoxContent &&
        ['newCollections', 'sceneBanner']?.includes(
          currentDownBoxContent?.mode
        )) ||
      (currentSmallBoxContent &&
        ['explore', 'installer']?.includes(currentSmallBoxContent?.mode)) ||
      (currentRightBoxContent &&
        ['newCollections', 'sceneBanner']?.includes(
          currentRightBoxContent?.mode
        ))
    )
      return true
    else {
      return false
    }
  }, [currentDownBoxContent, currentSmallBoxContent, currentRightBoxContent])

  return (
    <>
      {/* 顶部固定的 nav */}
      <motion.div
        id="header"
        key="header-nav-root"
        className={cn(s.root, {
          [s.noneFixed]: navMode === 'none-fixed',
          [s.fixed]: navMode === 'fixed',
          [s.rootTransparentMargin]: navSetting?.navBg === 'transparent',
          [s.rootDark]: rootDark,
          [s.logoDark]: rootTransparent,
          [s.fixedReady]: fixedReady,
          [s.downShow]: !fixedReady && downShow,
          [s.downHidden]: fixedReady && !downShow && !dropDownBoxOpen,
          [s.downShowMob]: !rootTransparent,
        })}
        onMouseEnter={() => setNavMainBoxBg(true)}
        onMouseLeave={onMouseLeaveHidden}
      >
        <div className="only-in-desktop relative">
          {/* 手机端 顶部固定 nav */}
          <div className={cn('layer relative z-10', s.navLine)}>
            <div className={cn(s.content, 'content')}>
              <div className={s.navTop}>
                <div className={s.left}>
                  {mobileShowDetail && !isShowNext ? (
                    <motion.button
                      className={s.logoButton}
                      transition={{
                        duration: 0.2,
                        ease: easingTransitions?.easeInOutQuart,
                      }}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      onClick={() => {
                        setDropDownSmallBoxContent('')
                        setDropDownRightBoxContent('')
                        setCollectionHover(-1)
                        setDropDownBoxContent('')
                        router.replace(
                          {
                            pathname: router.pathname,
                            query: router.query,
                          },
                          undefined,
                          { shallow: true }
                        )
                      }}
                    >
                      <Icon
                        iconKey="arrowPrev"
                        width="20"
                        height="20"
                        className={s.icon}
                      />
                    </motion.button>
                  ) : (
                    <button
                      aria-label="go home"
                      className={s.logoButton}
                      onClick={() => {
                        window.location.assign(
                          `${locale === 'us' ? '' : `/${locale}`}${
                            pageType === 'charger' ? '/' : '/anker-solix'
                          }${
                            pageType === 'charger'
                              ? '?ref=naviMenu'
                              : '?ref=naviMenu_pps'
                          }`
                        )
                      }}
                    >
                      <Ankerlogo
                        pageType={pageType}
                        rootDark={rootDark}
                        locale={locale}
                        s={s}
                      />
                    </button>
                  )}
                </div>

                {mobileShowDetail && !isShowNext && (
                  <motion.div
                    className={s.mobileContentTitle}
                    transition={{
                      duration: 0.2,
                      ease: easingTransitions?.easeInOutQuart,
                    }}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    {currentDownBoxContent?.text ||
                      currentSmallBoxContent?.text ||
                      currentRightBoxContent?.text}
                  </motion.div>
                )}

                <div className={cn(s.actionBox, s.right)}>
                  {SEARCH && (!mobileShowDetail || isShowNext) && (
                    <button
                      id="search"
                      aria-label="search"
                      alt="search"
                      tag="Auto-10000110"
                      onClick={() => {
                        setShowMobileSearchBoard(true)
                        setDropDownBoxContent('')
                        setDropDownSmallBoxContent('')
                        setDropDownRightBoxContent('')
                        onSearchClick()
                        setMobileMenuOpen(false)

                        setCollectionHover(-1)
                        setDropDownBoxOpen(false)
                        setNavMainBoxBg(false)
                      }}
                      className={s.search}
                    >
                      <Icon iconKey="search" className={s.icon}></Icon>
                    </button>
                  )}

                  {CART && (!mobileShowDetail || isShowNext) && (
                    <button
                      className={s.cart}
                      onClick={() => {
                        setSidebarView('CART_VIEW')
                        toggleSidebar()
                        onMobileMenuHidden()
                      }}
                      aria-label={`Cart items: ${itemsCount}`}
                      tag="Auto-10000112"
                    >
                      <Icon iconKey="cart" className={s.icon}></Icon>
                      {itemsCount > 0 && (
                        <span className={s.bagCount}>{itemsCount}</span>
                      )}
                    </button>
                  )}

                  <div className="flex w-[20px] items-center">
                    {mobileMenuOpen ? (
                      <button
                        className={s.menuIcon}
                        onClick={() => {
                          onMobileMenuHidden()
                          router.replace(
                            {
                              pathname: router.pathname,
                              query: router.query,
                            },
                            undefined,
                            { shallow: true }
                          )
                        }}
                        aria-label="menu"
                      >
                        <Icon
                          iconKey="close"
                          width="24"
                          height="24"
                          className={s.icon}
                        />
                      </button>
                    ) : (
                      <button
                        className={s.menuIcon}
                        onClick={() => {
                          setMobileMenuOpen(true)
                        }}
                        aria-label="menu"
                      >
                        <Icon
                          iconKey="menu"
                          width="24"
                          height="24"
                          className={s.icon}
                        ></Icon>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <motion.div
            className={cn(s.navMainBoxBg, 'md:hidden')}
            initial={{
              opacity: rootTransparent ? 0 : 1,
            }}
            animate={() => (rootTransparent ? 'transparent' : 'normal')}
            variants={{
              transparent: {
                opacity: 0,
                transition: {
                  delay: 0.2,
                  duration: 0.2,
                  ease: easingTransitions?.easeInOutQuart,
                },
              },
              normal: {
                opacity: 1,
                transition: {
                  duration: 0.2,
                  ease: easingTransitions?.easeInOutQuart,
                },
              },
            }}
          ></motion.div>

          <div
            className={cn(
              s.navMainBoxBg,
              'hidden md:block',
              rootTransparent ? 'opacity-0' : 'opacity-100'
            )}
          ></div>

          {/* 手机端 展开后 Box */}
          <UnfoldWingsMobile
            lang={lang}
            rootDark={rootDark}
            shopCommon={shopCommon}
            pageType={pageType}
            categories={categories}
            mobileShowDetail={mobileShowDetail}
            isShowNext={isShowNext}
            mobileMenuOpen={mobileMenuOpen}
            dropDownContent={dropDownContent}
            dropDownSmallContent={dropDownSmallContent}
            dropDownRightContent={dropDownRightContent}
            currentDownBoxContent={currentDownBoxContent}
            currentDownBoxContentAll={currentDownBoxContentAll}
            currentSmallBoxContent={currentSmallBoxContent}
            currentRightBoxContent={currentRightBoxContent}
            setDropDownSmallBoxContent={setDropDownSmallBoxContent}
            setDropDownRightBoxContent={setDropDownRightBoxContent}
            setDropDownBoxContent={setDropDownBoxContent}
            onMobileMenuHidden={onMobileMenuHidden}
            metafields={metafields}
            setUserPopLeft={setUserPopLeft}
            userPopLeft={userPopLeft}
            onRegistrations={handleRegistrations}
            registrationsSettings={registrationsSettings}
          />
        </div>

        <div className="not-in-desktop">
          {/* PC端 顶部 nav */}

          <div className={cn(s.navMainBox, s.navLine)}>
            <div className={cn('relative z-10 w-[100vw]')}>
              <div className={cn('layer_max', s.navMainTopBox)}>
                <div
                  className={cn(
                    s.navMain,
                    'content flex h-[100%] items-center justify-between'
                  )}
                >
                  <button
                    className={s.logoButton}
                    onClick={() => {
                      window.location.assign(
                        `${locale === 'us' ? '' : `/${locale}`}${
                          pageType === 'charger' ? '/' : '/anker-solix'
                        }${
                          pageType === 'charger'
                            ? '?ref=naviMenu'
                            : '?ref=naviMenu_pps'
                        }`
                      )
                    }}
                  >
                    <Ankerlogo
                      pageType={pageType}
                      rootDark={rootDark}
                      locale={locale}
                      s={s}
                    />
                  </button>
                  <div className="flex items-center gap-[24px]">
                    {SEARCH && (
                      <button
                        id="search"
                        aria-label="search"
                        alt="search"
                        tag="Auto-10000110"
                        onClick={() => {
                          setNotificationPopLeft(false)
                          setDropDownBoxContent('')
                          setDropDownSmallBoxContent('')
                          setDropDownRightBoxContent('')
                          setShowSearchBoard(!showSearchBoard)
                          onSearchClick()
                        }}
                        className={cn(s.search, {
                          [s.searchSpace]: navNotification,
                        })}
                        onMouseEnter={() => {
                          setUserPopLeft(false)
                        }}
                      >
                        <Icon iconKey="search" className={s.icon}></Icon>
                      </button>
                    )}
                    {navNotification && (
                      <button
                        id="notification"
                        aria-label="notification"
                        alt="notification"
                        onMouseEnter={() => {
                          setUserPopLeft(false)
                          setDropDownBoxContent('')
                          setDropDownSmallBoxContent('')
                          setDropDownRightBoxContent('')
                          let left
                          const l =
                            $(`#notification`)?.getBoundingClientRect()?.left
                          const cardWidth = 480
                          if (l > window.innerWidth - cardWidth) {
                            left = window.innerWidth - cardWidth
                          } else {
                            left = l - cardWidth / 2
                          }
                          Cookies.set('isNotification', true)
                          setIsNotification(false)
                          setNotificationPopLeft(left)
                        }}
                        className={s.notification}
                      >
                        <Icon iconKey="notification" className={s.icon}></Icon>
                      </button>
                    )}
                    {CUSTOMERAUTH && (
                      <UserNavChg
                        onRegistrations={handleRegistrations}
                        registrationsSettings={registrationsSettings}
                        className={s.userNav}
                        showUserBoard={showUserBoard}
                        onClick={() => {
                          setShowUserBoard(!showUserBoard)
                        }}
                        onMouseEnter={() => {
                          setNotificationPopLeft(false)
                          setDropDownBoxContent('')
                          setDropDownSmallBoxContent('')
                          setDropDownRightBoxContent('')
                          let left
                          const l =
                            $(`#userIcon`)?.getBoundingClientRect()?.left
                          const cardWidth = 230
                          left = l - cardWidth + 40
                          setUserPopLeft(left)
                          setShowUserBoard(true)
                        }}
                        data={navUser}
                      />
                    )}
                    {CART && (
                      <button
                        className={s.cart}
                        onClick={() => {
                          setSidebarView('CART_VIEW')
                          toggleSidebar()
                        }}
                        onMouseEnter={() => {
                          setUserPopLeft(false)
                        }}
                        tag="Auto-10000112"
                        aria-label={`Cart items: ${itemsCount}`}
                      >
                        <Icon iconKey="cart" className={s.icon}></Icon>
                        {itemsCount > 0 && (
                          <span className={s.bagCount}>{itemsCount}</span>
                        )}
                      </button>
                    )}
                    {!shopPreRender?.hidenLiveComponent && (
                      <LiveComponent hasLiveContent={hasLiveContent} />
                    )}
                  </div>
                </div>
              </div>
              <div className="layer_max">
                <div className={cn(s.navMain, 'content')}>
                  <div className={cn(s.left, s.navMenuTo)}>
                    <div className={s.navMenuTitle}>
                      {dropDownContent &&
                        dropDownContent?.map((item, i) => {
                          return item?.mode === 'linkto' ? (
                            <a
                              className={cn(s.link, 'relative block', {
                                [s.hover]: item?.text === dropDownBoxContent,
                              })}
                              key={`dropDownContent-${item?.text}-${i}`}
                              onMouseEnter={() => {
                                setDropDownBoxOpen(false)
                                setDropDownPosition(0)
                                setDropDownBoxContent(item?.text)
                                setDropDownSmallBoxContent('')
                                setDropDownRightBoxContent('')
                                onMobileMenuHidden()
                                setNavMainBoxBg(true)
                              }}
                              href={`${setRelativeLink({ link: item?.url })}${
                                pageType === 'charger'
                                  ? '?ref=naviMenu'
                                  : '?ref=naviMenu_pps'
                              }`}
                            >
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: item?.text,
                                }}
                              />
                              {item?.hotIcon && (
                                <div className={s.hotWrap}>
                                  <Picture
                                    className="h-full w-full object-cover"
                                    source={`${item?.hotIcon} 769`}
                                  />
                                </div>
                              )}
                            </a>
                          ) : (
                            <motion.button
                              className={cn(s.button, 'relative', {
                                [s.hover]: item?.text === dropDownBoxContent,
                              })}
                              key={`dropDownContent-${item?.text}-${i}`}
                              onMouseEnter={() => {
                                setDropDownPosition(0)
                                setDropDownBoxContent(item?.text)
                                setDropDownSmallBoxContent('')
                                setDropDownRightBoxContent('')
                                setTimeout(() => setDropDownBoxOpen(true))
                              }}
                            >
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: item?.text,
                                }}
                              />

                              {item?.hotIcon && (
                                <div className={cn(s.hotWrap, '!right-[8px]')}>
                                  <Picture
                                    className="h-full w-full object-cover"
                                    source={`${item?.hotIcon} 769`}
                                  />
                                </div>
                              )}
                            </motion.button>
                          )
                        })}
                    </div>
                  </div>

                  <div className={cn(s.right, s.navLinkTo)}>
                    {dropDownSmallContent &&
                      dropDownSmallContent?.map((item, i) => {
                        return (
                          <motion.button
                            className={cn(s.button, {
                              [s.hover]: item?.text === dropDownSmallBoxContent,
                            })}
                            key={`dropDownSmallContent-${item?.text}-${i}`}
                            onMouseEnter={(e) => {
                              const layerX =
                                e.target?.getBoundingClientRect()?.left || 0
                              setDropDownBoxContent('')
                              setDropDownSmallBoxContent(item?.text)
                              setDropDownPosition(layerX)
                              setUserPopLeft(false)
                              setNotificationPopLeft(false)
                              setTimeout(() => setDropDownBoxOpen(true))
                            }}
                          >
                            <span>{item?.text}</span>
                            <Icon
                              iconKey="arrowDown"
                              fill="#000"
                              width="12"
                              height="12"
                            />
                          </motion.button>
                        )
                      })}
                    {dropDownRightContent &&
                      dropDownRightContent?.map((item, i) => {
                        return item?.mode === 'linkto' ? (
                          <a
                            className={cn(s.link, 'relative block', {
                              [s.hover]: item?.text === dropDownBoxContent,
                            })}
                            key={`dropDownContent-${item?.text}-${i}`}
                            onMouseEnter={() => {
                              setDropDownBoxOpen(false)
                              setUserPopLeft(false)
                              setDropDownPosition(0)
                              setDropDownBoxContent('')
                              setDropDownRightBoxContent(item?.text)
                              setDropDownSmallBoxContent('')
                              onMobileMenuHidden()
                            }}
                            href={`${setRelativeLink({ link: item?.url })}${
                              pageType === 'charger'
                                ? '?ref=naviMenu'
                                : '?ref=naviMenu_pps'
                            }`}
                          >
                            <span
                              dangerouslySetInnerHTML={{
                                __html: item?.text,
                              }}
                            />
                            {item?.hotIcon && (
                              <div className={s.hotWrap}>
                                <Picture
                                  className="h-full w-full object-cover"
                                  source={`${item?.hotIcon} 769`}
                                />
                              </div>
                            )}
                          </a>
                        ) : (
                          <motion.button
                            className={cn(s.button, 'relative', {
                              [s.hover]: item?.text === dropDownRightBoxContent,
                            })}
                            key={`dropDownRightContent-${item?.text}-${i}`}
                            onMouseEnter={() => {
                              setDropDownPosition(0)
                              setUserPopLeft(false)
                              setDropDownBoxContent('')
                              setDropDownSmallBoxContent('')
                              setDropDownRightBoxContent(item?.text)
                              setTimeout(() => setDropDownBoxOpen(true))
                            }}
                          >
                            <span>{item?.text}</span>
                            {/* <Icon
                              iconKey="arrowDown"
                              fill="#000"
                              width="12"
                              height="12"
                            /> */}
                            {item?.hotIcon && (
                              <div className={cn(s.hotWrap, '!right-[8px]')}>
                                <Picture
                                  className="h-full w-full object-cover"
                                  source={`${item?.hotIcon} 769`}
                                />
                              </div>
                            )}
                          </motion.button>
                        )
                      })}
                  </div>
                </div>
              </div>
            </div>
            <motion.div
              className={s.navMainBoxBg}
              initial={{
                opacity: rootTransparent ? 0 : 1,
              }}
              animate={() => (rootTransparent ? 'transparent' : 'normal')}
              variants={{
                transparent: {
                  opacity: 0,
                  transition: {
                    delay: 0.2,
                    duration: 0.2,
                    ease: easingTransitions?.easeInOutQuart,
                  },
                },
                normal: {
                  opacity: 1,
                  transition: {
                    duration: 0.2,
                    ease: easingTransitions?.easeInOutQuart,
                  },
                },
              }}
            ></motion.div>
          </div>

          {/* PC端 展开后 Box */}
          <UnfoldWingsPC
            lang={lang}
            shopCommon={shopCommon}
            pageType={pageType}
            categories={categories}
            dropDownPosition={dropDownPosition}
            currentDownBoxContent={
              currentRightBoxContent || currentDownBoxContent
            }
            currentSmallBoxContent={currentSmallBoxContent}
            onMouseLeaveHidden={onMouseLeaveHidden}
            setAnimate={() => {
              if (!dropDownBoxOpen && dropDownSmallBoxContent)
                return 'initSmall'
              if (dropDownBoxOpen && dropDownSmallBoxContent) return 'openSmall'
              if (
                (dropDownBoxOpen && dropDownBoxContent) ||
                (dropDownBoxOpen && currentRightBoxContent) ||
                showSearchBoard
              )
                return 'open'
              return 'closed'
            }}
            showSearchBoard={showSearchBoard}
            setShowSearchBoard={setShowSearchBoard}
            navSearch={metafields.navSearch}
          />

          <Notification
            data={navNotification}
            notificationPopLeft={notificationPopLeft}
            setNotificationPopLeft={setNotificationPopLeft}
          />

          <User
            data={metafields?.navUser}
            userPopLeft={userPopLeft}
            showUserBoard={showUserBoard}
            preRender={preRender}
            onRegistrations={handleRegistrations}
            registrationsSettings={registrationsSettings}
          />
        </div>
      </motion.div>

      <AnimatePresence>
        {showMobileSearchBoard && (
          <MobileSearchBoard
            data={metafields.navSearch}
            onClose={() => setShowMobileSearchBoard(false)}
          />
        )}
      </AnimatePresence>
    </>
  )
}

export default Header
