import cn from 'classnames'
import s from './Header.module.css'
import dynamic from 'next/dynamic'
import { useMemo, useState, useEffect, useRef } from 'react'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { appendRefParameter } from '@lib/utils/tools'
import { useRelativeLink } from '@commerce/product/use-relative-link'
import { ButtonV2, LoadingDots } from '@components/ui'

import { motion, AnimatePresence } from 'framer-motion'
import { User } from '@components/common/UserNav/UserNavChg'

import {
  CheckCircleIcon,
  ChevronRightIcon,
  ChevronDownIcon,
} from '@heroicons/react/24/solid'

import useCustomer from '@shopify/customer/use-customer'
import { Picture } from '@components/ui'
import { commerceEnabled } from '@lib/utils/tools'
import { easingTransitions } from '@lib/utils/animate'

const Loading = () => (
  <div className="flex h-80 w-full items-center justify-center p-3 text-center">
    <LoadingDots />
  </div>
)
const dynamicProps = {
  loading: Loading,
}
const SceneBanner = dynamic(() => import('./SceneBanner'), {
  ...dynamicProps,
})
const NewExplore = dynamic(() => import('./Explore'), {
  ...dynamicProps,
})
const Installer = dynamic(() => import('./Installer'), {
  ...dynamicProps,
})

const UnfoldWingsMobile = ({
  lang,
  pageType,
  rootDark,
  shopCommon,
  mobileMenuOpen,
  mobileShowDetail,
  isShowNext,
  hasLiveContent,
  dropDownContent,
  dropDownSmallContent,
  dropDownRightContent,
  currentDownBoxContent,
  currentSmallBoxContent,
  currentRightBoxContent,
  setDropDownRightBoxContent,
  setDropDownSmallBoxContent,
  setDropDownBoxContent,
  onMobileMenuHidden,
  getCollection,
  getMainCollection,
  metafields,
  setUserPopLeft,
  userPopLeft,
  onRegistrations,
  registrationsSettings,
}) => {
  const scrollRef = useRef()
  const router = useRouter()
  const { locale } = router
  const { setRelativeLink } = useRelativeLink()
  const { data: customer } = useCustomer()
  const { navUser, navNotification, preRender } = metafields || {}

  const [collectionHover, setCollectionHover] = useState(0)

  const currentCollectionHover = useMemo(() => {
    return (
      currentDownBoxContent && currentDownBoxContent?.content?.[collectionHover]
    )
  }, [currentDownBoxContent, collectionHover])

  const mobileDropBoxHeight = useMemo(() => {
    if (!mobileMenuOpen) return
    const dom = document?.querySelector('#header')
    return window?.innerHeight - dom?.getBoundingClientRect()?.bottom || 0
  }, [mobileMenuOpen])

  const CUSTOMERAUTH = useMemo(
    () => commerceEnabled('customerAuth', locale),
    [locale]
  )

  useEffect(() => {
    const contentElement = scrollRef.current

    if (contentElement && mobileMenuOpen) {
      disableBodyScroll(contentElement, {
        reserveScrollBarGap: true,
      })
    }
    return () => {
      clearAllBodyScrollLocks()
    }
  }, [mobileShowDetail, mobileMenuOpen])

  const [initialNotification, animateNotification, exitNotification] = [
    {
      opacity: 0,
    },
    {
      opacity: 1,
      transition: {
        ease: easingTransitions?.easeInOutQuart,
        duration: 0.3,
      },
    },
    {
      opacity: 0,
      transition: {
        ease: easingTransitions?.easeInQuart,
        duration: 0.4,
      },
    },
  ]

  const handleLiveChat = (e) => {
    e.preventDefault()
    const sidebar = document.querySelector('.helpButtonEnabled')
    if (sidebar) {
      sidebar.click()
    }
  }

  const [curIndex, setCurIndex] = useState(-1)

  return (
    <AnimatePresence>
      {mobileMenuOpen && (
        <motion.div
          layout
          className={cn(s.mobileDropBox)}
          initial={{
            height: '0px',
          }}
          animate={{
            height: `${mobileDropBoxHeight}px`,
          }}
          exit={{
            height: `${mobileDropBoxHeight}px`,
          }}
        >
          <div className={cn(s.mobileContent)} ref={scrollRef}>
            <AnimatePresence mode="popLayout">
              {/* 手机端 展开后 Box - 内容区域 第一屏 */}
              {(!mobileShowDetail || isShowNext) && (
                <div className={cn(s.content)}>
                  {lang?.navTopTitle && (
                    <div className="bg-[#fff] px-[80px] pb-0 pt-[24px] md:!px-[24px] l:px-[40px]">
                      <a
                        href={
                          lang?.navTopToHref
                            ? `${setRelativeLink({
                                link: lang?.navTopToHref,
                              })}`
                            : `${
                                locale === 'us' ? '' : `/${locale}`
                              }/anker-solix?ref=naviMenu`
                        }
                        className="border-[#1D1D1F]-500/100 flex w-full border-b pb-[12px] text-[16px] font-[500] text-[#1D1D1F] underline"
                      >
                        <span>{lang?.navTopTitle}</span>
                        <ChevronRightIcon width={18} />
                      </a>
                    </div>
                  )}
                  <ul className={s.menuMobile}>
                    {dropDownContent &&
                      dropDownContent?.map((item, i) => {
                        return item?.mode === 'linkto' ? (
                          <li
                            key={`menuMobile-${item?.text}-${i}`}
                            className={cn(s.layerPadding, s.option)}
                          >
                            <a
                              href={`${setRelativeLink({
                                link: item?.url,
                              })}?ref=naviMenu`}
                              className={s.linkButton}
                              onClick={() => {
                                onMobileMenuHidden()
                              }}
                            >
                              <div className="relative">
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: item?.text,
                                  }}
                                />
                                {item?.hotIcon && (
                                  <div className={s.hotWrap}>
                                    <Picture
                                      className="h-full w-full object-cover"
                                      source={`${item?.hotIcon} 769`}
                                    />
                                  </div>
                                )}
                              </div>
                              <ChevronRightIcon width={18} />
                            </a>
                          </li>
                        ) : ['sceneBanner']?.includes(item?.mode) ? (
                          <li
                            key={`menuMobile-${item?.text}-${i}`}
                            className={cn(s.layerPadding, s.option)}
                          >
                            <button
                              className={cn(
                                `${
                                  curIndex === i
                                    ? '!text-[var(--brand-color)]'
                                    : ''
                                }`
                              )}
                              onClick={() => {
                                setCollectionHover(-1)
                                setDropDownBoxContent(item?.text)
                                window.location.hash = `navi_2_${item?.text
                                  ?.toLowerCase()
                                  .replace(/\s+/g, '_')}`
                                if (i === curIndex) {
                                  setCurIndex(-1)
                                } else {
                                  setCurIndex(i)
                                }
                              }}
                            >
                              <div className="relative">
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: item?.text,
                                  }}
                                />
                                {item?.hotIcon && (
                                  <div className={s.hotWrap}>
                                    <Picture
                                      className="h-full w-full object-cover"
                                      source={`${item?.hotIcon} 769`}
                                    />
                                  </div>
                                )}
                              </div>
                              <ChevronRightIcon
                                className={cn(
                                  'transition-all',
                                  `${
                                    curIndex === i
                                      ? 'rotate-[90deg]'
                                      : 'rotate-[0deg]'
                                  }`
                                )}
                                width={18}
                              />
                            </button>
                            <motion.div
                              className={s.optionContent}
                              animate={curIndex === i ? 'show' : 'hidden'}
                              variants={{
                                show: {
                                  height: 'auto',
                                  transition: {
                                    duration: 0.3,
                                    ease: easingTransitions?.easeInOutQuart,
                                  },
                                },
                                hidden: {
                                  height: 0,
                                },
                              }}
                            >
                              {currentDownBoxContent?.mode ===
                                'sceneBanner' && (
                                <SceneBanner
                                  s={s}
                                  currentDownBoxContent={currentDownBoxContent}
                                  collectionHover={collectionHover}
                                  currentCollectionHover={
                                    currentCollectionHover
                                  }
                                  setCollectionHover={setCollectionHover}
                                  shopCommon={shopCommon}
                                ></SceneBanner>
                              )}
                            </motion.div>
                          </li>
                        ) : (
                          <li
                            key={`menuMobile-${item?.text}-${i}`}
                            className={cn(s.layerPadding, s.option)}
                          >
                            <button
                              onClick={() => {
                                setCollectionHover(-1)
                                setDropDownBoxContent(item?.text)
                                window.location.hash = `navi_2_${item?.text
                                  ?.toLowerCase()
                                  .replace(/\s+/g, '_')}`
                              }}
                            >
                              <div className="relative">
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: item?.text,
                                  }}
                                />
                                {item?.hotIcon && (
                                  <div className={s.hotWrap}>
                                    <Picture
                                      className="h-full w-full object-cover"
                                      source={`${item?.hotIcon} 769`}
                                    />
                                  </div>
                                )}
                              </div>
                              <ChevronRightIcon width={18} />
                            </button>
                          </li>
                        )
                      })}

                    {dropDownSmallContent && (
                      <hr
                        className={cn(
                          s.widthPadding,
                          'mt-[24px] h-[1px] bg-[#1D1D1F] bg-opacity-[0.08]'
                        )}
                      />
                    )}
                    {dropDownSmallContent &&
                      dropDownSmallContent?.map((item, i) => {
                        return ['explore', 'installer']?.includes(
                          item?.mode
                        ) ? (
                          <li
                            className={cn(s.layerPadding, s.option)}
                            key={`menuMobile-s-${item?.text}-${i}`}
                          >
                            <button
                              onClick={() => {
                                setDropDownSmallBoxContent(item?.text)
                                window.location.hash = `navi_2_${item?.text
                                  ?.toLowerCase()
                                  .replace(/\s+/g, '_')}`
                                if (i + 'smallContent' === curIndex) {
                                  setCurIndex(-1)
                                } else {
                                  setCurIndex(i + 'smallContent')
                                }
                              }}
                              className={cn(
                                `${
                                  curIndex === i + 'smallContent'
                                    ? '!text-[var(--brand-color)]'
                                    : ''
                                }`
                              )}
                            >
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: item?.text,
                                }}
                              />
                              <ChevronRightIcon
                                className={cn(
                                  'transition-all',
                                  `${
                                    curIndex === i + 'smallContent'
                                      ? 'rotate-[90deg]'
                                      : 'rotate-[0deg]'
                                  }`
                                )}
                                width={18}
                              />
                            </button>
                            <motion.div
                              className={s.optionContent}
                              animate={
                                curIndex === i + 'smallContent'
                                  ? 'show'
                                  : 'hidden'
                              }
                              variants={{
                                show: {
                                  height: 'auto',
                                  transition: {
                                    duration: 0.3,
                                    ease: easingTransitions?.easeInOutQuart,
                                  },
                                },
                                hidden: {
                                  height: 0,
                                },
                              }}
                            >
                              {currentSmallBoxContent?.mode === 'explore' && (
                                <NewExplore
                                  currentSmallBoxContent={
                                    currentSmallBoxContent
                                  }
                                  handleLiveChat={handleLiveChat}
                                  s={s}
                                />
                              )}
                              {currentSmallBoxContent?.mode === 'installer' && (
                                <Installer
                                  currentSmallBoxContent={
                                    currentSmallBoxContent
                                  }
                                  pageType={pageType}
                                  s={s}
                                />
                              )}
                            </motion.div>
                          </li>
                        ) : (
                          <li
                            className={cn(s.layerPadding, s.option)}
                            key={`menuMobile-s-${item?.text}-${i}`}
                          >
                            <button
                              onClick={() => {
                                setDropDownSmallBoxContent(item?.text)
                                window.location.hash = `navi_2_${item?.text
                                  ?.toLowerCase()
                                  .replace(/\s+/g, '_')}`
                              }}
                            >
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: item?.text,
                                }}
                              />
                              <ChevronRightIcon width={18} />
                            </button>
                          </li>
                        )
                      })}

                    {dropDownRightContent && (
                      <hr
                        className={cn(
                          s.widthPadding,
                          'mt-[24px] h-[1px] bg-[#1D1D1F] bg-opacity-[0.08]'
                        )}
                      />
                    )}
                    {dropDownRightContent &&
                      dropDownRightContent?.map((item, i) => {
                        return ['explore', 'installer']?.includes(
                          item?.mode
                        ) ? (
                          <li
                            className={cn(s.layerPadding, s.option)}
                            key={`menuMobile-s-${item?.text}-${i}`}
                          >
                            <button
                              onClick={() => {
                                setDropDownSmallBoxContent(item?.text)
                                window.location.hash = `navi_2_${item?.text
                                  ?.toLowerCase()
                                  .replace(/\s+/g, '_')}`
                                if (i + 'smallContent' === curIndex) {
                                  setCurIndex(-1)
                                } else {
                                  setCurIndex(i + 'smallContent')
                                }
                              }}
                              className={cn(
                                `${
                                  curIndex === i + 'smallContent'
                                    ? '!text-[var(--brand-color)]'
                                    : ''
                                }`
                              )}
                            >
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: item?.text,
                                }}
                              />
                              <ChevronRightIcon
                                className={cn(
                                  'transition-all',
                                  `${
                                    curIndex === i + 'smallContent'
                                      ? 'rotate-[90deg]'
                                      : 'rotate-[0deg]'
                                  }`
                                )}
                                width={18}
                              />
                            </button>
                            <motion.div
                              className={s.optionContent}
                              animate={
                                curIndex === i + 'smallContent'
                                  ? 'show'
                                  : 'hidden'
                              }
                              variants={{
                                show: {
                                  height: 'auto',
                                  transition: {
                                    duration: 0.3,
                                    ease: easingTransitions?.easeInOutQuart,
                                  },
                                },
                                hidden: {
                                  height: 0,
                                },
                              }}
                            >
                              {currentSmallBoxContent?.mode === 'explore' && (
                                <NewExplore
                                  currentSmallBoxContent={
                                    currentSmallBoxContent
                                  }
                                  handleLiveChat={handleLiveChat}
                                  s={s}
                                />
                              )}
                              {currentSmallBoxContent?.mode === 'installer' && (
                                <Installer
                                  currentSmallBoxContent={
                                    currentSmallBoxContent
                                  }
                                  pageType={pageType}
                                  s={s}
                                />
                              )}
                            </motion.div>
                          </li>
                        ) : (
                          <li
                            className={cn(s.layerPadding, s.option)}
                            key={`menuMobile-s-${item?.text}-${i}`}
                          >
                            <button
                              onClick={() => {
                                setDropDownRightBoxContent(item?.text)
                                window.location.hash = `navi_2_${item?.text
                                  ?.toLowerCase()
                                  .replace(/\s+/g, '_')}`
                              }}
                            >
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: item?.text,
                                }}
                              />
                              <ChevronRightIcon width={18} />
                            </button>
                          </li>
                        )
                      })}
                  </ul>

                  {navNotification && (
                    <div className={s.layerPadding}>
                      <hr
                        initial={initialNotification}
                        animate={animateNotification}
                        exit={exitNotification}
                      />

                      {navNotification?.title && (
                        <motion.p
                          className={s.notificationTitle}
                          initial={initialNotification}
                          animate={animateNotification}
                          exit={exitNotification}
                        >
                          {navNotification?.title}
                        </motion.p>
                      )}

                      <ul className={s.notificationCol}>
                        {navNotification?.list?.map((item, index) => {
                          return (
                            <li key={index}>
                              <a
                                className={s.notificationBox}
                                href={`${setRelativeLink({
                                  link: item?.href,
                                })}?ref=naviNotification`}
                              >
                                <Picture
                                  className={cn('h-[80px] w-[80px] flex-none')}
                                  source={`${item?.mobImg} 768`}
                                />
                                <div className="flex flex-col gap-[6px]">
                                  {item.title && (
                                    <p
                                      className={s.notificationBoxTitle}
                                      dangerouslySetInnerHTML={{
                                        __html: item.title,
                                      }}
                                    />
                                  )}

                                  {item.desc && (
                                    <p
                                      className={s.notificationBoxDesc}
                                      dangerouslySetInnerHTML={{
                                        __html: item.desc,
                                      }}
                                    />
                                  )}
                                </div>
                              </a>
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  )}

                  {/* 手机端 展开后 Box - 登陆 */}
                  {CUSTOMERAUTH && !mobileShowDetail && navUser && (
                    <div className="layer_max py-[24px]">
                      <div className="content">
                        <User
                          showUser={true}
                          data={navUser}
                          preRender={preRender}
                          onRegistrations={onRegistrations}
                          registrationsSettings={registrationsSettings}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}

              {/* 手机端 展开后 Box - 内容区域 第二屏 */}
              {mobileShowDetail && !isShowNext && (
                <div className={cn(s.mobileMenuDetail, 'content')}>
                  {(currentDownBoxContent ||
                    currentSmallBoxContent ||
                    currentRightBoxContent) && (
                    <div
                      key={`${currentDownBoxContent?.mode}-${currentDownBoxContent?.text}`}
                      className={cn(s.content)}
                    >
                      <CurrentDownBoxContent
                        currentDownBoxContent={
                          currentDownBoxContent || currentRightBoxContent
                        }
                        collectionHover={collectionHover}
                        setCollectionHover={setCollectionHover}
                        shopCommon={shopCommon}
                        lang={lang}
                      />

                      {currentSmallBoxContent?.imgTitle && (
                        <p
                          key={`dropDownLeftContent-${currentSmallBoxContent?.imgTitle}`}
                          className={cn(s.linkToTitle, s.layerPadding)}
                        >
                          {currentSmallBoxContent?.imgTitle}
                        </p>
                      )}
                      {currentSmallBoxContent?.imgList?.map((item, g) => {
                        return (
                          <a
                            className={cn(s.linkToBtn, s.layerPadding)}
                            href={`${setRelativeLink({
                              link: item?.url,
                            })}?ref=naviMenu`}
                            key={`dropDownSmallContentimg${item?.img}-${g}`}
                          >
                            <Picture source={item?.img} alt="" />
                          </a>
                        )
                      })}
                      {currentSmallBoxContent?.textTitle && (
                        <p
                          key={`dropDownRightContent-${currentSmallBoxContent?.textTitle}`}
                          className={cn(s.linkToTitle, s.layerPadding)}
                        >
                          {currentSmallBoxContent?.textTitle}
                        </p>
                      )}
                      {currentSmallBoxContent?.textList?.map((item, g) => {
                        return (
                          <a
                            href={`${setRelativeLink({
                              link: item?.url,
                            })}?ref=naviMenu`}
                            onClick={(e) => {
                              item?.url === 'online-message' &&
                                handleLiveChat(e)
                            }}
                            key={`dropDownSmallContenta-${item?.url}-${g}`}
                            className={cn(s.linkToBtn, s.layerPadding)}
                          >
                            {item?.text}
                          </a>
                        )
                      })}
                    </div>
                  )}
                </div>
              )}
            </AnimatePresence>
          </div>

          {/* 手机端 展开后 Box - 底部固定条 */}
          <AnimatePresence mode="popLayout">
            {mobileShowDetail &&
              !isShowNext &&
              currentDownBoxContent &&
              currentDownBoxContent?.mode === 'collectionByHandle' && (
                <div className={cn('layer', s.mobileDropBottom)}>
                  <div className={cn('content flex items-center')}>
                    <ButtonV2 className={'w-full'}>
                      <a
                        href={
                          currentDownBoxContent?.shopAllLink
                            ? `${setRelativeLink({
                                link: currentDownBoxContent?.shopAllLink,
                              })}?ref=naviMenu`
                            : `${locale === 'us' ? '' : `/${locale}`}${
                                currentDownBoxContent?.path
                              }?ref=naviMenu`
                        }
                      >
                        {currentDownBoxContent?.jumpCollection
                          ? currentDownBoxContent.jumpCollection
                          : lang?.seeAll?.replace(
                              '%collection%',
                              currentDownBoxContent?.text
                            )}
                      </a>
                    </ButtonV2>
                  </div>
                </div>
              )}
          </AnimatePresence>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default UnfoldWingsMobile

const CurrentDownBoxContent = ({
  currentDownBoxContent,
  collectionHover,
  setCollectionHover,
  shopCommon,
  lang,
}) => {
  const router = useRouter()
  const { locale } = router
  const { setRelativeLink } = useRelativeLink()
  switch (currentDownBoxContent?.mode) {
    case 'collectionByHandle':
      return (
        <ul className={cn(s.menuMobile, s.collectionByHandle)}>
          {currentDownBoxContent?.content?.map((collection, index) => {
            return (
              <li
                key={`collectionsMLi-${collection?.name}-${index}`}
                className={cn(s.option, {
                  [s.active]: collectionHover === index,
                })}
              >
                <button
                  className={s.layerPadding}
                  onClick={() => {
                    if (collectionHover >= 0 && collectionHover === index) {
                      setCollectionHover(-1)
                    } else {
                      setCollectionHover(index)
                    }
                    window.location.hash = `navi_3_${collection?.name
                      ?.toLowerCase()
                      .replace(/\s+/g, '_')}`
                  }}
                >
                  <span>{collection?.name}</span>
                  <ChevronDownIcon
                    width={18}
                    className={s.collectionMenuDown}
                  />
                </button>

                <motion.div
                  className={s.optionContent}
                  animate={collectionHover === index ? 'show' : 'hidden'}
                  variants={{
                    show: {
                      height: 'auto',
                      transition: {
                        duration: 0.3,
                        ease: easingTransitions?.easeInOutQuart,
                      },
                    },
                    hidden: {
                      height: 0,
                    },
                  }}
                >
                  {collection?.starCard && (
                    <div className={s.navStarCardList}>
                      {collection?.productList?.map((item, index) => {
                        return (
                          <motion.a
                            href={`${setRelativeLink({
                              link: item?.url,
                            })}?ref=naviMenu`}
                            className={s.navStarCardBox}
                            key={`starCard-${collectionHover}-${item?.name}-${index}`}
                            initial="initial"
                            animate="animate"
                            exit="exit"
                          >
                            <Picture
                              className="h-full w-full object-cover"
                              source={`${item?.img}, ${item?.img1024} 1024, ${item?.img768} 768`}
                            />
                            <div
                              className={cn(s.navStarCardContent, {
                                [s.drak]: item?.theme === 'drak',
                              })}
                            >
                              <p className={s.navStarCardTitle}>
                                {item?.title}
                              </p>
                              <p className={s.navStarCardSub}>
                                {item?.subtitle}
                              </p>
                              <p className={s.navStarCardLearn}>
                                {item?.learnmore}
                              </p>
                            </div>
                          </motion.a>
                        )
                      })}
                    </div>
                  )}
                  {collection?.starProducts && (
                    <div
                      className={cn(s.navStarList, {
                        [s.navStarListMore]:
                          collection?.productList?.length > 1,
                      })}
                    >
                      {collection?.productList?.map((item, index) => {
                        const url = appendRefParameter({
                          url: item?.url,
                          refValue: 'naviMenu',
                        })
                        return (
                          <a
                            href={setRelativeLink({
                              link: url,
                            })}
                            className={s.productBox}
                            key={`starProducts-${item?.name}-${index}`}
                          >
                            <div className={s.detail}>
                              <p
                                className={s.tag}
                                dangerouslySetInnerHTML={{
                                  __html: item?.tag,
                                }}
                              />
                              <p
                                className={s.name}
                                dangerouslySetInnerHTML={{
                                  __html: item?.name,
                                }}
                              />
                              <p
                                className={s.description}
                                dangerouslySetInnerHTML={{
                                  __html: item?.description,
                                }}
                              />
                              <p
                                className={s.learnMore}
                                dangerouslySetInnerHTML={{
                                  __html: item?.learnMore,
                                }}
                              />
                            </div>
                            <div className={s.productImg}>
                              <Picture source={item?.img} alt="" />
                            </div>
                            <ul className={s.highlights}>
                              {item?.highlights?.length
                                ? item?.highlights
                                    ?.split('||')
                                    ?.map((txt, i) => {
                                      return (
                                        <li
                                          key={`${txt}-${i}`}
                                          className={s.hightLight}
                                        >
                                          <CheckCircleIcon
                                            className="mr-2 flex-initial"
                                            width="20"
                                            stroke="#ff9128"
                                          />
                                          <span>{txt}</span>
                                        </li>
                                      )
                                    })
                                : ''}
                            </ul>
                          </a>
                        )
                      })}
                      <div className={s.otherLinksBox}>
                        {collection?.otherLinks?.map((item, index) => {
                          return (
                            <a
                              href={setRelativeLink({
                                link: item?.to,
                              })}
                              className={s.HOtherLinks}
                              key={`HOtherLinks-${collectionHover}-${index}`}
                              dangerouslySetInnerHTML={{
                                __html: item?.label,
                              }}
                            />
                          )
                        })}
                      </div>
                    </div>
                  )}
                  {!collection?.starProducts && !collection?.starCard && (
                    <div className={s.productsListBox}>
                      <ul className={s.productsList}>
                        <AnimatePresence mode="popLayout">
                          {collection?.productList?.map((item, g) => {
                            const tag = item.tags?.find(
                              (tag) =>
                                tag in (shopCommon.tagToDescription || {})
                            )
                            const tags =
                              item?.metafields?.productTags?.split(',') || []
                            return g < 6 ? (
                              <li
                                className={s.product}
                                key={`productListM-${collection?.name}-${item?.name}-${g}`}
                              >
                                <a
                                  rel="noopener noreferrer"
                                  href={`${
                                    locale === 'us' ? '' : `/${locale}`
                                  }/products${item?.path}?ref=naviMenu`}
                                  className={s.producta}
                                >
                                  <div className={s.imgBox}>
                                    <Image
                                      src={item?.images?.[0]?.url}
                                      height={100}
                                      width={100}
                                      alt={item?.name}
                                      sizes="140px"
                                    />
                                  </div>
                                  <div
                                    className={cn(
                                      'flex flex-1 flex-col gap-[4px]'
                                    )}
                                  >
                                    {tag && (
                                      <p className={s.tag}>
                                        {shopCommon.tagToDescription?.[tag]
                                          ?.label ||
                                          shopCommon.tagToDescription?.[tag] ||
                                          ''}
                                      </p>
                                    )}
                                    <p
                                      className={s.name}
                                      dangerouslySetInnerHTML={{
                                        __html: item?.name,
                                      }}
                                    />
                                    {tags?.length > 0 && (
                                      <div className="flex gap-[4px]">
                                        {tags?.map((tag, i) => (
                                          <span
                                            key={tag + i}
                                            className="bg-[#EEEEEF] px-[4px] py-[2px] text-[12px] font-[500] leading-[1.4] text-[#1D1D1F]"
                                          >
                                            {tag}
                                          </span>
                                        ))}
                                      </div>
                                    )}
                                  </div>
                                </a>
                              </li>
                            ) : (
                              ''
                            )
                          })}
                        </AnimatePresence>
                      </ul>
                      {collection?.jumpCollection !== 'hidden' && (
                        <div className="mt-[12px] flex justify-start">
                          <span className="text-[14px] font-[500] leading-[1.4] text-[#777]">
                            {lang?.seeCollection
                              ?.replace(
                                '%collection1%',
                                currentDownBoxContent?.text
                              )
                              ?.replace('%collection2%', collection?.name)}
                          </span>
                          <a
                            href={`${setRelativeLink({
                              link: collection?.path,
                            })}?ref=naviMenu`}
                            rel="noopener noreferrer"
                            className="ml-[12px] !w-auto !text-[14px] font-[500] text-[#1D1D1F] underline"
                          >
                            <span className="whitespace-nowrap">
                              {lang?.viewAll}
                            </span>
                            <ChevronRightIcon className="ml-[5px] block w-[12px] stroke-[#1D1D1F] stroke-[1px] pb-[1px]" />
                          </a>
                        </div>
                      )}
                    </div>
                  )}
                </motion.div>
              </li>
            )
          })}
          {currentDownBoxContent?.otherLinks?.map((item, index) => (
            <li
              key={`collectionsMLi-${item?.label}-${index}`}
              className={cn(s.option, s.layerPadding, 'pt-[24px]')}
            >
              <a
                href={`${setRelativeLink({
                  link: item?.to,
                })}?ref=naviMenu`}
                rel="noopener noreferrer"
                className={cn(s.linkTo)}
              >
                <span>{item?.label}</span>
                <ChevronDownIcon
                  width={18}
                  className={cn(s.collectionMenuDown, 'rotate-[-90deg]')}
                />
              </a>
            </li>
          ))}
        </ul>
      )
    case 'collections':
      return (
        <ul className={cn(s.menuMobile)}>
          {currentDownBoxContent?.content?.map((collection, index) => {
            return (
              <li
                key={`collectionsMLi-${collection?.name}-${index}`}
                className={cn(s.option, {
                  [s.active]: collectionHover === index,
                })}
              >
                <button
                  className={s.layerPadding}
                  onClick={() => {
                    if (collectionHover >= 0 && collectionHover === index) {
                      setCollectionHover(-1)
                    } else {
                      setCollectionHover(index)
                    }
                    window.location.hash = `navi_3_${collection?.name
                      ?.toLowerCase()
                      .replace(/\s+/g, '_')}`
                  }}
                >
                  <span>{collection?.name}</span>
                  <ChevronDownIcon
                    width={18}
                    className={s.collectionMenuDown}
                  />
                </button>

                <motion.div
                  className={s.optionContent}
                  animate={collectionHover === index ? 'show' : 'hidden'}
                  variants={{
                    show: {
                      height: 'auto',
                      transition: {
                        duration: 0.3,
                        ease: easingTransitions?.easeInOutQuart,
                      },
                    },
                    hidden: {
                      height: 0,
                    },
                  }}
                >
                  {collection?.starCard && (
                    <div className={cn(s.productsListBox, s.navStarCardList)}>
                      {collection?.productList?.map((item, index) => {
                        return (
                          <motion.a
                            href={`${setRelativeLink({
                              link: item?.url,
                            })}?ref=naviMenu`}
                            className={s.navStarCardBox}
                            key={`starCard-${collectionHover}-${item?.name}-${index}`}
                            initial="initial"
                            animate="animate"
                            exit="exit"
                          >
                            <Picture
                              className="h-full w-full object-cover"
                              source={`${item?.img}, ${item?.img1024} 1024, ${item?.img768} 768`}
                            />
                            <div
                              className={cn(s.navStarCardContent, {
                                [s.drak]: item?.theme === 'drak',
                              })}
                            >
                              <p className={s.navStarCardTitle}>
                                {item?.title}
                              </p>
                              <p className={s.navStarCardSub}>
                                {item?.subtitle}
                              </p>
                              <p className={s.navStarCardLearn}>
                                {item?.learnmore}
                              </p>
                            </div>
                          </motion.a>
                        )
                      })}
                    </div>
                  )}
                  {collection?.starProducts && (
                    <div
                      className={cn(s.navStarList, s.productsListBox, {
                        [s.navStarListMore]:
                          collection?.productList?.length > 1,
                      })}
                    >
                      {collection?.productList?.map((item, index) => {
                        const url = appendRefParameter({
                          url: item?.url,
                          refValue: 'naviMenu',
                        })
                        return (
                          <a
                            href={setRelativeLink({
                              link: url,
                            })}
                            className={s.productBox}
                            key={`starProducts-${item?.name}-${index}`}
                          >
                            <div className={s.detail}>
                              <p
                                className={s.tag}
                                dangerouslySetInnerHTML={{
                                  __html: item?.tag,
                                }}
                              />
                              <p
                                className={s.name}
                                dangerouslySetInnerHTML={{
                                  __html: item?.name,
                                }}
                              />
                              <p
                                className={s.description}
                                dangerouslySetInnerHTML={{
                                  __html: item?.description,
                                }}
                              />
                              <p
                                className={s.learnMore}
                                dangerouslySetInnerHTML={{
                                  __html: item?.learnMore,
                                }}
                              />
                            </div>
                            <div className={s.productImg}>
                              <Picture source={item?.img} alt="" />
                            </div>
                            <ul className={s.highlights}>
                              {item?.highlights?.length
                                ? item?.highlights
                                    ?.split('||')
                                    ?.map((txt, i) => {
                                      return (
                                        <li
                                          key={`${txt}-${i}`}
                                          className={s.hightLight}
                                        >
                                          <CheckCircleIcon
                                            className="mr-2 flex-initial"
                                            width="20"
                                            stroke="#ff9128"
                                          />
                                          <span>{txt}</span>
                                        </li>
                                      )
                                    })
                                : ''}
                            </ul>
                          </a>
                        )
                      })}
                      <div className={s.otherLinksBox}>
                        {collection?.otherLinks?.map((item, index) => {
                          return (
                            <a
                              href={setRelativeLink({
                                link: item?.to,
                              })}
                              className={s.HOtherLinks}
                              key={`HOtherLinks-${collectionHover}-${index}`}
                              dangerouslySetInnerHTML={{
                                __html: item?.label,
                              }}
                            />
                          )
                        })}
                      </div>
                    </div>
                  )}
                  {!collection?.starProducts && !collection?.starCard && (
                    <div className={s.productsListBox}>
                      <ul className={s.productsList}>
                        <AnimatePresence mode="popLayout">
                          {collection?.productList?.map((item, g) => {
                            const tag = item.tags?.find(
                              (tag) =>
                                tag in (shopCommon.tagToDescription || {})
                            )
                            return g < 6 ? (
                              <li
                                className={s.product}
                                key={`productListM-${collection?.name}-${item?.name}-${g}`}
                              >
                                <a
                                  rel="noopener noreferrer"
                                  href={`${
                                    locale === 'us' ? '' : `/${locale}`
                                  }/products${item?.path}?ref=naviMenu`}
                                  className={s.producta}
                                >
                                  <div className={s.imgBox}>
                                    <Image
                                      src={item?.images?.[0]?.url}
                                      height={100}
                                      width={100}
                                      alt={item?.name}
                                      sizes="140px"
                                    />
                                  </div>
                                  <p
                                    className={s.name}
                                    dangerouslySetInnerHTML={{
                                      __html: item?.name,
                                    }}
                                  />
                                  {tag && (
                                    <p className={s.tag}>
                                      {shopCommon.tagToDescription?.[tag]
                                        ?.label ||
                                        shopCommon.tagToDescription?.[tag] ||
                                        ''}
                                    </p>
                                  )}
                                </a>
                              </li>
                            ) : (
                              ''
                            )
                          })}
                        </AnimatePresence>
                      </ul>

                      {collection?.jumpCollection !== 'hidden' && (
                        <div className={s.bottomBar}>
                          <ul className={s.list}>
                            <AnimatePresence mode="popLayout">
                              {collection?.collectionList?.map((item, g) => {
                                return item?.name ? (
                                  <li
                                    key={`collectionList-${collection?.name}-${item}-${g}`}
                                  >
                                    <a
                                      href={`${
                                        locale === 'us' ? '' : `/${locale}`
                                      }${item?.path}?ref=naviMenu`}
                                      rel="noopener noreferrer"
                                      className={s.linkTo}
                                    >
                                      {item?.name} &gt;&gt;
                                    </a>
                                  </li>
                                ) : (
                                  ''
                                )
                              })}
                            </AnimatePresence>
                          </ul>
                          <div className="mt-[12px] flex justify-start">
                            <a
                              href={`${setRelativeLink({
                                link: collection?.path,
                              })}?ref=naviMenu`}
                              rel="noopener noreferrer"
                              className="!w-auto !text-[14px] font-[500] text-[#1D1D1F] underline"
                            >
                              <span className="text-[14px] font-[500] leading-[1.4] text-[#777]">
                                {collection?.jumpCollection
                                  ? collection.jumpCollection
                                  : lang?.seeAll?.replace(
                                      '%collection%',
                                      collection?.name
                                    )}
                              </span>
                              <ChevronRightIcon className="ml-[5px] block w-[12px] stroke-[#1D1D1F] stroke-[1px] pb-[1px]" />
                            </a>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </motion.div>
              </li>
            )
          })}
        </ul>
      )
    case 'navBanner':
      return (
        <div className={cn(s.layerPadding, 'pt-[24px]')}>
          {currentDownBoxContent?.title && (
            <a
              className={s.solixTitle}
              href={`${setRelativeLink({
                linnk: currentDownBoxContent?.to,
              })}?ref=naviMenu`}
            >
              <span>{currentDownBoxContent?.title}</span>
              <ChevronRightIcon className={s.solixSvg} />
            </a>
          )}

          {currentDownBoxContent?.content?.map((item, index) => {
            return (
              <a
                key={`navBanner-${item?.title}-${index}`}
                href={`${setRelativeLink({
                  link: item?.href,
                })}?ref=naviMenu`}
                rel="noopener noreferrer"
                className={cn(s.navBanner, {
                  [s.firstBanner]: index === 0,
                })}
              >
                <Picture
                  source={item?.mobileBgImg ? item?.mobileBgImg : item?.bgImg}
                  alt=""
                />
                <div
                  className={cn(s.navBannerTxt, {
                    [s.modeDark]: item?.mode?.toLowerCase() === 'dark',
                  })}
                >
                  <span
                    className={s.navBannerTitle}
                    dangerouslySetInnerHTML={{
                      __html: item?.title,
                    }}
                  />
                  <span
                    dangerouslySetInnerHTML={{
                      __html: item?.subtitle,
                    }}
                  />
                  <p
                    className={s.viewMore}
                    dangerouslySetInnerHTML={{
                      __html: item?.learnmore,
                    }}
                  />
                </div>
              </a>
            )
          })}
        </div>
      )
    case 'gather':
      return (
        <div className={cn(s.layerPadding, s.gather)}>
          {currentDownBoxContent?.content?.map((item, index) => {
            if (item?.mode == 'row') {
              return (
                <div
                  key={index}
                  className={cn('flex w-full flex-col', {
                    ['border-[#1D1D1F]-500/100 border-t pt-[24px]']:
                      index !== 0,
                  })}
                >
                  {item?.title && (
                    <p className="mb-[12px] text-[16px] font-[500] leading-[1.4] text-[#1D1D1F]">
                      {item?.title}
                    </p>
                  )}

                  <div className="flex flex-col gap-[12px] text-[14px] font-[500] leading-[1.4] text-[#777]">
                    {item?.list?.map((child, childIndex) => (
                      <a
                        key={childIndex}
                        href={`${setRelativeLink({
                          link: child?.href,
                        })}?ref=naviMenu`}
                        className={s.gatherBox}
                      >
                        <p>{child?.title}</p>
                        {child?.hotIcon && (
                          <p className="flex h-[19px] items-center justify-center rounded-[20px] bg-[#F8EFED] px-[8px] text-[13px] text-[#FF5C00]">
                            {child?.hotIcon}
                          </p>
                        )}
                      </a>
                    ))}
                  </div>
                </div>
              )
            }

            if (item?.mode == 'image') {
              return (
                <div
                  key={index}
                  className={cn(
                    'flex w-[calc(50%-12px)] max-w-[424px] flex-col text-[#1D1D1F] md:w-full',
                    {
                      ['md:border-[#1D1D1F]-500/100 md:border-t md:pt-[24px]']:
                        index !== 0,
                    }
                  )}
                >
                  <div className="flex flex-col">
                    {item?.list?.map((child, childIndex) => (
                      <a
                        href={`${setRelativeLink({
                          link: child?.href,
                        })}?ref=naviMenu`}
                        key={childIndex}
                        className={cn(s.gatherBox, {
                          [s.gatherImgBox]: child?.img,
                        })}
                      >
                        <div className={s.imageWrap}>
                          <Picture
                            className={cn(
                              s.gatherImage,
                              'aspect-h-[206] aspect-w-[342]'
                            )}
                            source={`${child?.img}, ${
                              child?.mobImg || child?.img
                            } 769}`}
                            alt={child?.alt || ''}
                            imgClassName="w-full h-full object-cover"
                          />
                        </div>

                        <div className="mt-[16px]">
                          <div className="relative inline-block w-auto pr-[20px]">
                            <p className="line-clamp-1 text-[16px] leading-[1.4]">
                              {child?.title}
                            </p>
                            <ChevronRightIcon className="absolute right-0 top-1/2 block w-[12px] -translate-y-1/2 stroke-[#1D1D1F] stroke-[1px] pb-[3px]" />
                          </div>
                          <p className="mt-[4px] text-[12px] leading-[1.4] text-[#777]">
                            {child?.subtitle}
                          </p>
                        </div>
                      </a>
                    ))}
                  </div>
                </div>
              )
            }
          })}
        </div>
      )
  }
}
